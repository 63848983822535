import {useEffect, useState} from 'react';

const useIsPrinting = () => {
  const [isPrinting, setIsPrinting] = useState(false);

  useEffect(() => {
    const onBeforePrint = () => {
      setIsPrinting(true);
    };

    const onAfterPrint = () => {
      setIsPrinting(false);
    };

    window.addEventListener('beforeprint', onBeforePrint);
    window.addEventListener('afterprint', onAfterPrint);

    return () => {
      window.removeEventListener('beforeprint', onBeforePrint);
      window.removeEventListener('afterprint', onAfterPrint);
    };
  }, []);

  return isPrinting;
};

export default useIsPrinting;