import {Transition} from '@headlessui/react';
import {mdiClose, mdiPrinterCheck} from '@mdi/js';
import {Icon} from '@mdi/react';
import React, {useState} from 'react';

const printShortcut = {
  mac: '⌘P',
  windows: 'Ctrl+P',
  linux: 'Ctrl+P',
  other: 'Ctrl+P',
}[navigator.userAgent.match(/(Windows|Linux|Mac)/i)[0].toLowerCase() || 'other'];

const PrintFriendlyOverlay = () => {
  const [shown, setShown] = useState(true);

  return (
    <Transition
      as="div"
      show={shown}
      leaveFrom="scale-100 opacity-100"
      leaveTo="scale-y-0 opacity-0"
      className="group max-lg:hidden print:hidden origin-bottom flex items-center gap-3 px-3 py-2 absolute bottom-0 right-10 bg-secondary hover:shadow-lg delay-500 hover:delay-[0ms] duration-300 rounded-t-xl select-none overflow-hidden"
    >
      <Icon path={mdiPrinterCheck} className="w-6"/>
      <div>
        <div className="text-sm font-medium mb-1">This Page is Print Friendly</div>
        <div
          className="text-xs text-center -mt-5 group-hover:mt-0 translate-y-10 group-hover:translate-y-0 duration-300">Press {printShortcut?.split('+')?.map((key, index, arr) => <>
          <kbd className="bg-white rounded px-1 py-0.5 border shadow-sm">{key}</kbd>{index < arr.length - 1 && ' + '}</>)} <b>OR</b> use
          browser/context
          menu to print
        </div>
      </div>
      <button type="button" className="self-start p-2 -mx-3 -my-2" onClick={() => setShown(false)}>
        <Icon path={mdiClose} className="w-4"/>
      </button>
    </Transition>
  );
};

export default PrintFriendlyOverlay;