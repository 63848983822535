import {createInertiaApp} from '@inertiajs/inertia-react';
import {AccountMenu, FormErrorContextProvider, InitialApplicationContext, MainLayout} from '@webfox/webfox-ui';
import * as React from 'react';
import {createElement, ReactElement, StrictMode} from 'react';
import {createRoot} from 'react-dom/client';
import 'Style/app.css';
import {accountMenuItems, navItems} from './MainLayoutConfig';
import DevModeListener from "./Components/DevModeListener";
import DevModeOverlay from "./Components/DevModeOverlay";
import PrintWrapper from "./Components/PrintWrapper";
import PrintLayout from "./Layouts/PrintLayout/PrintLayout";
import PrintFriendlyOverlay from "./Components/PrintFriendlyOverlay";

createInertiaApp<Window['inertiaInitialPageData']['props']>({
    resolve: async (name) => {
        const comps = import.meta.glob('./Pages/**/*.{tsx,jsx}');
        const match = comps[`./Pages/${name}.tsx`] ?? comps[`./Pages/${name}.jsx`];
        if (!match) {
            throw new Error(`You've attempted to load the Inertia.js page 'Pages/${name}.{tsx,jsx}', but no file with that name exists.`);
        }

        return (await match()).default;
    },
    page: window.inertiaInitialPageData,
    setup({el, App, props}) {
        createRoot(el).render(
            <StrictMode>
                <InitialApplicationContext.Provider value={{application: props.initialPage.props.application}}>
                    <App {...props}>
                        {({key, props, Component}) => {
                            // @ts-ignore
                            const componentLayout: (page: ReactElement) => ReactElement | Array<(page: ReactElement) => ReactElement> = Component.layout;
                            const page = createElement(Component, {key, ...props});

                            let children;

                            if (Array.isArray(componentLayout)) {
                                children = componentLayout
                                    .concat(page)
                                    .reverse()
                                    .reduce((children, Layout) => createElement(Layout, {children}));
                            } else if (typeof componentLayout === 'function') {
                                children = componentLayout(page);
                            } else {
                                children = (
                                    <PrintWrapper>
                                        {(isPrinting) => isPrinting ?
                                            <PrintLayout children={page}/>
                                            :
                                            <MainLayout
                                                children={page}
                                                navItems={navItems(props.application.user)}
                                                largeLogoSrc="/action-logo-no-text.png"
                                                smallLogoSrc="/action-logo-small.png"
                                                accountMenu={<AccountMenu accountMenuItems={accountMenuItems}/>}
                                                leftNavMainClassName="bg-primary"
                                                defaultClassName="text-secondary"
                                                logoContainerClassName="bg-primary"
                                                largeLogoClassName="px-6 py-2"
                                                smallLogoClassName="py-2 object-contain"
                                            />
                                        }
                                    </PrintWrapper>
                                );
                            }

                            return (
                                <FormErrorContextProvider>
                                    <DevModeListener>
                                        {children}
                                        {!!Component?.printFriendly && <PrintFriendlyOverlay/>}
                                        <DevModeOverlay/>
                                    </DevModeListener>
                                </FormErrorContextProvider>
                            );
                        }}
                    </App>
                </InitialApplicationContext.Provider>
            </StrictMode>
        );
    },
});
