import {mdiPrinter} from '@mdi/js';
import {Icon} from '@mdi/react';
import {formatLongDatetime} from '@webfox/webfox-ui';
import {PrintLayoutContext} from 'Layouts/PrintLayout/usePrintLayout';
import React, {useState} from 'react';

const PrintLayout = ({children}) => {
  const [printHeader, setPrintHeader] = useState(null);
  const [printFooter, setPrintFooter] = useState(null);

  return (
    <PrintLayoutContext.Provider value={{
      isPrinting: true,
      setPrintHeader,
      setPrintFooter,
      printHeader,
      printFooter,
    }}>
      <table className="print-preserve-colours w-full blur-lg print:blur-none">
        <thead className="table-header-group">
        <tr>
          <th>
            <div className="flex items-center justify-between gap-5 pb-1 mb-2 border-b font-normal text-left">
              <div className="flex items-center gap-5">
                <img src="/action-logo-transparent.png" alt="Logo" className="w-20 h-auto"/>
                <div className="leading-tight">
                  <div className="font-bold text-lg">ACTION</div>
                  <div className="text-sm text-gray-500">{formatLongDatetime(new Date().toISOString())}</div>
                </div>
              </div>
              {printHeader}
            </div>
          </th>
        </tr>
        </thead>
        <tfoot className="table-footer-group">
        <tr>
          <td>
            {!!printFooter &&
              <div className="pt-2 mt-2 border-t">
                {printFooter}
              </div>
            }
          </td>
        </tr>
        </tfoot>
        <tbody>
        <tr>
          <td>
            {children}
          </td>
        </tr>
        </tbody>
      </table>
      <div className="fixed bottom-10 inset-x-0 flex items-center justify-center gap-2 print:hidden">
        <div className="flex flex-col items-center gap-1 py-3 px-4 bg-white shadow-xl rounded-xl">
          <div className="flex items-center gap-2 text-xl font-medium">
            <Icon path={mdiPrinter} className="w-6"/>
            <div>Page is in Print Friendly Mode</div>
          </div>
          <div className="text-xs text-gray-500 text-center">NOTE: If the page remains in print friendly mode after the print has been
            completed/cancelled<br/>please refresh the page
          </div>
        </div>
      </div>
    </PrintLayoutContext.Provider>
  );
};

export default PrintLayout;