import {createContext, useContext} from 'react';

export const PrintLayoutContext = createContext({
  isPrinting: false,
  setPrintHeader: () => {},
  setPrintFooter: () => {},
  printHeader: null,
  printFooter: null,
});

export const usePrintLayout = () => {
  return useContext(PrintLayoutContext);
};

export default usePrintLayout;